import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const LandingPagesIndexPage = () => (
  <Layout>
    <Seo title="Nothing here" />
	<h2>Nothing here</h2>
	<div className="main--centerwrapper">
		<StaticImage
		src="../../images/photos/pressRes/_MG_1300.jpg"
		alt="Jesse Rivest press image"
		placeholder="blurred"
		layout="constrained"
		className="main--centered"
		imgClassName="img--bordered-small"
		width={500}
		/>
	</div>
	<h3>There's nothing here!</h3>
	<p>
		Please use the footer links to navigate elsewhere.
	</p>
  </Layout>
);

export default LandingPagesIndexPage;
